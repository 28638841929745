import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DebounceDirective} from '../directives';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        DebounceDirective
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DebounceDirective
    ]
})
export class ComponentsModule {
}
