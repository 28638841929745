import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {CoreModule} from './lib/modules';
import {ErrorInterceptor, JwtInterceptor} from './structure/auth/interceptors';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import { CpfPipe } from './lib/utils/CpfPipe';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

@NgModule({
    imports: [
        CoreModule,
        AppRoutingModule,
        SweetAlert2Module.forRoot()
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
