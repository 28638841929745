import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, map, Observable, of} from 'rxjs';

import {environment} from '../../../../environments/environment';
import {StorageService} from '../../../lib/services';

@Injectable({providedIn: 'root'})
export class AuthService {
    public currentUser: Observable<Token>;
    private currentUserSubject: BehaviorSubject<Token>;

    public get currentUserValue(): Token {
        return this.currentUserSubject.value;
    }

    private get url(): string {
        return `${environment.backendApiUrl}/Patient`;
    }

    constructor(
        private http: HttpClient,
        private storage: StorageService) {
        this.currentUserSubject = new BehaviorSubject<Token>(this.storage.getParse('currentUser'));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    login(email: string, password: string): Observable<any> {
        // const token: Token = {
        //     userName: 'KLAUS SILVEIRA VEBER',
        //     userId: 5825,
        //     type: 'Bearer',
        //     token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaWQiOiI1ODI1IiwiZW1haWwiOiJrbGF1c0Bjb25lY3RhZG9jLmNvbS5iciIsImV4cCI6MTY0ODAyMTc0NCwiaXNzIjoiSXNzdWVyIiwiYXVkIjoiYXVkaWVuY2UifQ.wwye6IQiBagLJ1GezoQJaMCWN8mwA3y39cfB_PW4aB0',
        //     refreshToken: 'not yet implemented',
        //     expiration: '2022-03-23T07:49:04.985187+00:00'
        // };
        //
        // this.storage.setStringify('currentUser', token);
        // this.currentUserSubject.next(token);
        //
        // return new BehaviorSubject(token);

        return this.http.post<Token>(`${this.url}/loginSecretary`, {email, password})
            .pipe(map(response => {
                this.storage.setStringify('currentUser', response);
                this.currentUserSubject.next(response);
                return response;
            }));
    }
    setToken(token: Token): Observable<any> {
        this.storage.setStringify('currentUser', token);
        
        this.currentUserSubject.next(token);
        return new BehaviorSubject(token);
    }

    logout(): void {
        this.storage.remove('currentUser');        
        this.currentUserSubject.next(null);
    }

    getAbas(): Observable<AbasConfig[]> {
        const user = JSON.parse(this.storage.get("currentUser")) as Token;
        return of(user.abas)
    }
}

export interface Token {
    userName: string;
    userId: number;
    type: string;
    token: string;
    refreshToken: string;
    expiration: Date | string;
    abas: AbasConfig[];
}

export interface AbasConfig {
    idMultiAgendaAbasConfig: number;
    nomeAba: string;
    ativo?: boolean;
    idPaciente?: number;
}