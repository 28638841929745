export function AutoUnsubscribe(blackList = []) {

    // @ts-ignore
    return constructor => {
        const original = constructor.prototype.ngOnDestroy;

        constructor.prototype.ngOnDestroy = function () {
            for (const prop in this) {
                if (this.hasOwnProperty(prop)) {
                    const property = this[prop];
                    // @ts-ignore
                    if (!blackList.includes(prop)) {
                        if (property && (typeof property.unsubscribe === 'function')) {
                            property.unsubscribe();
                        }
                    }
                }
            }
            // tslint:disable-next-line:no-unused-expression
            original && typeof original === 'function' && original.apply(this, arguments);
        };
    };

}
