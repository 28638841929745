import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            switch (err.status) {
                case 401:
                    return this.unauthorised();
                case 403:
                    return this.forbidden();
                case 404:
                    return this.notFound();
                case 0:
                    return this.undefinedError();
                default:
                    return this.serviceError(err.error);
            }
        }));
    }

    private unauthorised(): Observable<any> {
        this.authenticationService.logout();
        return throwError(['Usuário não autenticado. Verifique Login e Senha informados.']);
    }

    private forbidden(): Observable<any> {
        return throwError(['Usuário não autorizado.']);
    }

    private notFound(): Observable<any> {
        return throwError(['Servidor não conseguiu encontrar o URL solicitada.']);
    }

    private undefinedError(): Observable<any> {
        return throwError(['Não foi possível se comunicar com o servidor.']);
    }

    private serviceError(error: any): Observable<any> {
        return throwError(error);
    }
}

