import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './structure/auth/services';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    constructor(
        private authService: AuthService,
        private router: Router) {
        this.authService.currentUser.subscribe(currentUser => {
            if (currentUser === undefined || currentUser === null) {
                this.router.navigate(['/auth/login']);
            }
            else{
                this.router.navigate(['/agendamento']);
            }
        });
    }
}
